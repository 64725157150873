<template>
  <v-container fluid>
    <div class="tables-basic">
      <h1 class="page-title mt-10 mb-6">Wiadomości</h1>
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-card-title class="pa-6 pb-3">
              <p>Odnotowano nowe wiadomości w otwartych zgłoszeniach</p> 
              <v-spacer></v-spacer>
              <v-text-field
                v-model="newSignals.search"
                append-icon="mdi-magnify"
                label="Szukaj"
                clearable
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              v-model="newSignals.selected"
              :headers="newSignals.headers"
              :items="newSignals.signals"
              :search="newSignals.search"
              item-key="identifier"
              :loading="loading"
              loading-text="Ładowanie... Proszę czekać"
              :items-per-page.sync="itemsPerPage"
                :hide-default-footer="false"
                :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }"
              > <template v-slot:[`item.actions`]="{ item }">
                    <v-btn text @click="showSignal(item)">
                        <v-icon color="red">mdi-message-processing</v-icon><div>Odczytaj zgłoszenie</div></v-btn>
                </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

    </div>
  </v-container>
</template>

<script>
    import Service from '@/services/Service'
export default {
  name: 'Reports',
  data() {
    return {
      newSignals: [],
        signals: [],
        itemsPerPage: 10,
        loading: true
    }
  },
  async mounted() {
      try {
          this.main = (await Service.getMessagesOpenSignals(20)).data,
            this.signalsDb = this.main.signals,
            this.newSignals = {
                selected: [],
                search: '',
                headers: [
                    {
                        text: 'Identyfikator',
                        align: 'start',
                        sortable: true,
                        value: 'identifier',
                    },
                    { text: 'Data wpływu', value: 'datetime' },
                    { text: 'Dni', value: 'days' },
                    { text: 'Etykieta', value: 'title' },
                    { text: 'Kategoria', value: 'category' },
                    { text: '', value: 'actions' },
                ],
                signals: this.signalsDb,
              },
              this.loading = false
        } catch (e) {
        if (e.response.status == 401)
            this.$router.push({ name: 'Login' })
    }
  },
  watch: {
      itemsPerPage: {
          handler() {
              if (this.itemsPerPage > 20 || this.itemsPerPage == -1) {
                  this.loadRecords();
              }
        },
        deep: true,
      },
    },
  methods: {
    showSignal(item) {
          this.$router.push({ name: 'Signal', params: { identifier: item.id } })
    },
      async loadRecords() {
          try {
              this.loading = true;
              this.main = (await Service.getMessagesOpenSignals(this.itemsPerPage)).data;
                  this.signalsDb = this.main.signals,
                  this.newSignals = {
                      selected: [],
                      search: '',
                      headers: [
                          {
                              text: 'Identyfikator',
                              align: 'start',
                              sortable: true,
                              value: 'identifier',
                          },
                          { text: 'Data wpływu', value: 'datetime' },
                          { text: 'Dni', value: 'days' },
                          { text: 'Etykieta', value: 'title' },
                          { text: 'Kategoria', value: 'category' },
                          { text: '', value: 'actions' },
                      ],
                      signals: this.signalsDb,
                  };
              this.loading = false;
          } catch (e) {
              console.log(e)
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      }
  }
}

</script>

<style src="../Reports/Basic.scss" lang="scss">
</style>
