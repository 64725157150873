<template>
  <v-container fluid>
      <div class="tables-basic">
          <h1 class="page-title mt-10 mb-6">Panel główny</h1>
          <v-row>
              <v-col cols="12">
                  <v-card class="employee-list mb-1">
                      <v-card-title class="pa-6 pb-3">
                          <p>Nowe zgłoszenia</p>
                          <v-spacer></v-spacer>
                          <v-text-field v-model="newSignals.search"
                                        append-icon="mdi-magnify"
                                        label="Szukaj"
                                        clearable
                                        single-line
                                        hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table v-model="newSignals.selected"
                                    :headers="newSignals.headers"
                                    :items="newSignals.signals"
                                    :search="newSignals.search"
                                    item-key="identifier"
                                    :loading="loading"
                                    loading-text="Ładowanie... Proszę czekać"
                                    :items-per-page.sync="itemsPerPage"
                                    :hide-default-footer="false"
                                    :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">
                          <template v-slot:[`item.icon`]="{ item }">
                              <v-icon v-if="item.icon">
                                  mdi-paperclip
                              </v-icon>
                          </template>
                          <template v-slot:[`item.type`]="{ item }">
                              <v-icon v-if="item.type == 2">
                                  mdi-phone
                              </v-icon>
                              <v-icon v-if="item.type == 3">
                                  mdi-email-outline
                              </v-icon>
                          </template>
                          <template v-slot:[`item.show`]="{ item }">
                              <v-btn text @click="showSignal(item)">
                                  <v-icon color="green">mdi-open-in-new</v-icon><div>Pokaż</div>
                              </v-btn>
                          </template>
                      </v-data-table>
                  </v-card>
              </v-col>
          </v-row>

          <v-row>
              <v-col cols="12">
                  <v-card class="employee-list mb-1">
                      <v-card-title class="pa-6 pb-3">
                          <p>Odnotowano nowe wiadomości w otwartych zgłoszeniach</p>
                          <v-spacer></v-spacer>
                          <v-text-field v-model="newMessages.search"
                                        append-icon="mdi-magnify"
                                        label="Szukaj"
                                        clearable
                                        single-line
                                        hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table v-model="newMessages.selected"
                                    :headers="newMessages.headers"
                                    :items="newMessages.signals"
                                    :search="newMessages.search"
                                    item-key="identifier"
                                    :loading="loading2"
                                    loading-text="Ładowanie... Proszę czekać"
                                    :items-per-page.sync="itemsPerPage2"
                                    :hide-default-footer="false"
                                    :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">
                          <template v-slot:[`item.actions`]="{ item }">
                              <v-btn text @click="showSignal(item)">
                                  <v-icon color="red">mdi-message-processing</v-icon><div>Odczytaj zgłoszenie</div>
                              </v-btn>
                          </template>
                      </v-data-table>
                  </v-card>
              </v-col>
          </v-row>

      </div>
  </v-container>
</template>

<script>
    import Service from '@/services/Service'

export default {
  name: 'New',
  data() {
    return {
      newSignals: [],
      signals: [],
      newMessages: [],
      signalsDb: [],
        messageDb: [],
        loading: true,
        loading2: true,
        itemsPerPage: 10,
        itemsPerPage2: 10
    }
  },
  created(){
        document.title = "Sygnali.pl - Panel administracyjny";
  },
  async mounted() {
      try {
          this.main = (await Service.main()).data,
          this.signalsDb = this.main.signals,
          this.messageDb = this.main.messages,
          this.newSignals = {
              selected: [],
              search: '',
              headers: [
                  {
                      text: 'Identyfikator',
                      align: 'start',
                      sortable: false,
                      value: 'identifier',
                      width: "1%"
                  },
                  { text: '', value: 'icon', sortable: false, width: "1%" },
                  { text: '', value: 'type', sortable: false, width: "1%" },
                  { text: 'Data wpływu', value: 'datetime' },
                  { text: 'Dni', value: 'days' },
                  { text: 'Odpowiedzi', value: 'replaysCount' },
                  { text: 'Etykieta', value: 'title' },
                  { text: 'Kategoria', value: 'category' },
                  { text: '', value: 'show', sortable: false},
              ],
              signals: this.signalsDb,
              },
              this.loading = false,
              this.newMessages = {
                  selected: [],
                  search: '',
                  headers: [
                      {
                          text: 'Identyfikator',
                          align: 'start',
                          sortable: false,
                          value: 'identifier',
                      },
                      { text: 'Data wpływu', value: 'datetime' },
                      { text: 'Dni', value: 'days' },
                      { text: 'Etykieta', value: 'title' },
                      { text: 'Kategoria', value: 'category' },
                      { text: '', value: 'actions', sortable: false },
                  ],
              signals: this.messageDb,
              },
              this.loading2 = false
      } catch (e) {
          if (e.response.status == 401)
              this.$router.push({ name: 'Login' })
      }
  },
  watch: {
            itemsPerPage: {
                handler() {
                    if (this.itemsPerPage > 20 || this.itemsPerPage == -1) {
                        this.loadRecords(this.itemsPerPage, 1);
                    }
                },
                deep: true,
            },
            itemsPerPage2: {
                handler() {
                    if (this.itemsPerPage2 > 20 || this.itemsPerPage2 == -1) {
                        this.loadRecords(this.itemsPerPage2, 2);
                    }
                },
                deep: true,
            }
        },
  methods: {
      showSignal(item) {
          this.$router.push({ name: 'Signal', params: { identifier: item.id}})
      },
      async loadRecords(count, type) {
          try {
              if (type == 1)
                  this.loading = true;
              if (type == 2)
                  this.loading2 = true;
              
              var result = (await Service.getDashboardSignalsForType(count, type)).data;
              if (type == 1) {
                  this.signalsDb = result;
                  this.newSignals = {
                      selected: [],
                      search: '',
                      headers: [
                          {
                              text: 'Identyfikator',
                              align: 'start',
                              sortable: false,
                              value: 'identifier',
                              width: "1%"
                          },
                          { text: '', value: 'icon', sortable: false, width: "1%" },
                          { text: '', value: 'type', sortable: false, width: "1%" },
                          { text: 'Data wpływu', value: 'datetime' },
                          { text: 'Dni', value: 'days' },
                          { text: 'Odpowiedzi', value: 'replaysCount' },
                          { text: 'Etykieta', value: 'title' },
                          { text: 'Kategoria', value: 'category' },
                          { text: '', value: 'show', sortable: false },
                      ],
                      signals: this.signalsDb,
                  }
              }
              if (type == 2) {
                  this.messageDb = result;
                  this.newMessages = {
                      selected: [],
                      search: '',
                      headers: [
                          {
                              text: 'Identyfikator',
                              align: 'start',
                              sortable: false,
                              value: 'identifier',
                          },
                          { text: 'Data wpływu', value: 'datetime' },
                          { text: 'Dni', value: 'days' },
                          { text: 'Etykieta', value: 'title' },
                          { text: 'Kategoria', value: 'category' },
                          { text: '', value: 'actions', sortable: false },
                      ],
                      signals: this.messageDb,
                  }
              }
              this.loading = false;
              this.loading2 = false;
          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      }
  }
}

</script>

<style src="./Basic.scss" lang="scss">
</style>
