var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"tables-basic"},[_c('h1',{staticClass:"page-title mt-10 mb-6"},[_vm._v("Panel główny")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"employee-list mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Nowe zgłoszenia")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Szukaj","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.newSignals.search),callback:function ($$v) {_vm.$set(_vm.newSignals, "search", $$v)},expression:"newSignals.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.newSignals.headers,"items":_vm.newSignals.signals,"search":_vm.newSignals.search,"item-key":"identifier","loading":_vm.loading,"loading-text":"Ładowanie... Proszę czekać","items-per-page":_vm.itemsPerPage,"hide-default-footer":false,"footer-props":{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [(item.icon)?_c('v-icon',[_vm._v(" mdi-paperclip ")]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type == 2)?_c('v-icon',[_vm._v(" mdi-phone ")]):_vm._e(),(item.type == 3)?_c('v-icon',[_vm._v(" mdi-email-outline ")]):_vm._e()]}},{key:"item.show",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.showSignal(item)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-open-in-new")]),_c('div',[_vm._v("Pokaż")])],1)]}}],null,true),model:{value:(_vm.newSignals.selected),callback:function ($$v) {_vm.$set(_vm.newSignals, "selected", $$v)},expression:"newSignals.selected"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"employee-list mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Odnotowano nowe wiadomości w otwartych zgłoszeniach")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Szukaj","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.newMessages.search),callback:function ($$v) {_vm.$set(_vm.newMessages, "search", $$v)},expression:"newMessages.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.newMessages.headers,"items":_vm.newMessages.signals,"search":_vm.newMessages.search,"item-key":"identifier","loading":_vm.loading2,"loading-text":"Ładowanie... Proszę czekać","items-per-page":_vm.itemsPerPage2,"hide-default-footer":false,"footer-props":{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage2=$event},"update:items-per-page":function($event){_vm.itemsPerPage2=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.showSignal(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-message-processing")]),_c('div',[_vm._v("Odczytaj zgłoszenie")])],1)]}}],null,true),model:{value:(_vm.newMessages.selected),callback:function ($$v) {_vm.$set(_vm.newMessages, "selected", $$v)},expression:"newMessages.selected"}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }