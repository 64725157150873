import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/components/Layout/Layout';

// Pages
import Login from "@/pages/Login/Login";
import Dashboard from '@/pages/Dashboard/Dashboard';
import New from '@/pages/Dashboard/New';
import Open from '@/pages/Dashboard/Open';
import Archive from '@/pages/Dashboard/Archive';
import All from '@/pages/Dashboard/All';
import Tables from '@/pages/Tables/Basic';
import Categories from '@/pages/Tables/Categories';
import Reports from '@/pages/Reports/Reports';
import Messages from '@/pages/Messages/Messages';
import Error from "@/pages/Error/Error";
import Error2 from "@/pages/Error/Error2";
import Signal from '@/pages/Signal/Signal';
import Inbox from '@/pages/Signal/Inbox';
import InboxLogin from '@/pages/Signal/InboxLogin';
import InboxPhoneLogin from '@/pages/Signal/InboxPhoneLogin';
import Registration from '@/pages/Login/Registration';
import ReportPrint from '@/pages/Reports/ReportPrint';
import ResetPassword from '@/pages/Login/ResetPassword';
import Reset from '@/pages/Login/Reset';
import Form from '@/pages/Signal/Form';
import Settings from '@/pages/Settings/Settings';
import Logs from '@/pages/LogsAct/Logs';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
    path: '/',
    redirect: 'login',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: 'new',
        name: 'New',
        component: New,
      },
      {
        path: 'open',
        name: 'Open',
        component: Open,
      },
      {
        path: 'archive',
        name: 'Archive',
        component: Archive,
      },
      {
        path: 'all',
        name: 'All',
        component: All,
      },
      {
        path: 'users',
        name: 'Tables',
        component: Tables
      },
      {
        path: 'categories',
        name: 'Categories',
        component: Categories
      },
      {
        path: 'logs',
        name: 'Logs',
        component: Logs
      },
      {
        path: '/reports',
        name: 'Reports',
        component: Reports
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings
      },
      {
        path: '/messages',
        name: 'Messages',
        component: Messages
      },
      {
        path: '/signal/:id',
        name: 'Signal',
        component: Signal,
        props: true
      },
    ],
  },
    {
      path: '*',
      name: 'Error',
      component: Error
    },
    {
        path: '/Errors',
        name: 'Error2',
        component: Error2
    },
    {
        path: '/form/:companyName',
        name: 'Form',
        component: Form,
        props: true
    },
    {
      path: '/inbox',
      name: 'Inbox',
      component: Inbox,
      props: true
    },
    {
      path: '/inboxlogin',
      name: 'InboxLogin',
      component: InboxLogin,
    },
    {
      path: '/inboxphonelogin',
      name: 'InboxPhoneLogin',
      component: InboxPhoneLogin,
    },
    {
      path: '/resetpassword',
      name: 'ResetPassword',
      component: ResetPassword,
    },
    {
      path: '/reset/:guidreset',
      name: 'Reset',
      component: Reset,
      props: true
    },
    {
        path: '/registration/:email',
        name: 'registration',
        component: Registration,
        props: true
    },
    {
        path: '/reportPrint',
        name: 'reportPrint',
        component: ReportPrint,
        props: true
    },
    ],
});
