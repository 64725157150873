<template>
  <v-navigation-drawer
    app
    clipped
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"
    :class="{'drawer-mini': !DRAWER_STATE}">
    <v-list>
      <template v-for="(item, i) in items">
        <v-row
          v-if="item.heading"
          :key="item.heading"
          align="center">
          <v-col cols="6" class="py-5">
            <span
              style="padding-left: 32px"
              class="text-body-1 subheader"
              :class="(item.heading && DRAWER_STATE) ? 'show ' : 'hide'">
              {{ item.heading }}
              </span>
          </v-col>
          <v-col
            cols="6"
            class="text-center">
          </v-col>
        </v-row>
        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-4"
        ></v-divider>
        <v-list-group
          color="primary"
          v-else-if="item.children && DRAWER_STATE"
          :key="item.title"
          v-model="item.model"
          append-icon="">
            <template v-slot:prependIcon>
              <v-icon size="28">mdi-image-filter-none</v-icon>
            </template>
            <template v-slot:activator >
              <v-list-item-content >
                <v-list-item-title
                  class="grey--text">
                    {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :to="child.link"
              link>
                <v-list-item-action v-if="child.icon">
                  <v-icon size="">{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    {{ child.title }}
                  </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
        <v-list-item
          color="primary"
          v-else
          :key="item.text"
          :href="item.href ? item.href : null"
          :to="item.link === '#' ? null : item.link"
          @click="goto(item.title)"
          link>
          <v-list-item-action>
            <v-icon
              size="28"
              :color="item.color ? item.color : ''"
            >{{ item.icon }}</v-icon>
          </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="grey--text"
                link>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
            <!--<v-list-item-action v-if="item.title == 'Wiadomości'">
                <div id="messageCount">{{messageCount}}</div>
              </v-list-item-action>-->
        </v-list-item>

      </template>
    </v-list>
    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Service from '@/services/Service'
  export default {
    props: {
        source: String,
    },
    data(){
      return {
        items: [
          { title: 'Panel główny', icon: 'mdi-home', link: '/dashboard', },
          { title: 'Zgłoszenia', icon: 'mdi-cards-outline', link: '/new', model: false, children: [
              { title: 'Nowe', icon: 'mdi-circle-small', link: '/new'},
              { title: 'Otwarte', icon: 'mdi-circle-small', link: '/open'},
              { title: 'Archiwalne', icon: 'mdi-circle-small', link: '/archive' },
              { title: 'Wszystkie', icon: 'mdi-circle-small', link: '/all'},
              ],
          },
          { title: 'Wiadomości', icon: 'mdi-message-text-outline', link: '/messages'},
          { title: 'Użytkownicy', icon: 'mdi-account-multiple', link: '/users' },
          { title: 'Kategorie', icon: 'mdi-clipboard-text', link: '/categories' },
          { title: 'Raporty', icon: ' mdi-sticker', link: '/reports' },
          { title: 'Ustawienia', icon: 'mdi-wrench', link: '/settings' },
          { title: 'Log systemowy', icon: 'mdi-account-search', link: '/logs' },
          { divider: true },
          { title: 'Formularz', icon: 'mdi-launch' },
          { title: 'Pomoc', icon: 'mdi-help-circle-outline'},
        ],
        sidebarWidth: 240,
        sidebarMinWidth: 96,
        linkToForm: '',
        messageCount: 0,
        main: {}
      }
    },
    computed: {
      ...mapState(['drawer']),
      DRAWER_STATE: {
        get() {
          return this.drawer
        },
        set(newValue) {
          if (newValue === this.drawer) return;
          this.TOGGLE_DRAWER();
        }
      }
    },
    async mounted() {
        try {
            this.linkToForm = (await Service.getLinkToForm()).data.linkToForm
            //this.linkToForm = this.main.linkToForm,
            //this.messageCount = this.main.messageCount
        } catch (e) {
            console.log(e)
        }
    },
    methods: {
        ...mapActions(['TOGGLE_DRAWER']),
        goto(item) {
            if (item == "Pomoc") {
                window.open('https://pomoc.mwc.pl/sygnali/', '_blank').focus();
            }
            if (item == "Formularz") {
                let routeData = this.$router.resolve({ path: `/form/${this.linkToForm}` });
                window.open(routeData.href, '_blank');
            }
        }
    }
  }
</script>

<style src="./Sidebar.scss" lang="scss"/>
