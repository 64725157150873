<template>
  <v-container fluid>
      <div class="tables-basic">
          <h1 class="page-title mt-10 mb-6">Log systemowy</h1>
          <v-row no-gutter>
              <v-col cols="12">
                      <v-card class="pa-3 pb-3">
                          <v-card-title class="pa-6 pb-3"><p>Wyszukaj aktywności</p></v-card-title>        
                          <v-row>
                              <v-col md="8">
                                  <v-date-picker v-model="dates" locale="pl" no-title range full-width></v-date-picker>
                              </v-col>
                              <v-col md="3">
                                  <v-text-field v-model="dateRangeText"
                                                label="Zakres dat"
                                                prepend-icon="mdi-calendar"
                                                readonly></v-text-field>
                                  <v-card-text class="pa-0 pb-0">
                                      <v-col class="d-flex" cols="12">
                                          <v-select v-model="selectedUser" label="Wybierz użytkownika" :items="users" item-text="name" item-value="id" @change="selectThisUser($event)" dense style="margin-left:10px;"></v-select>
                                      </v-col>
                                  </v-card-text>
                                  <v-btn @click="findLogsForAdmin()" color="" style="margin-right: 20px;" :disabled="dates.length == 0 && selectedUser == 0">SZUKAJ</v-btn> 
                                  <v-btn @click="findLogsAllForAdmin()" color="blue">POKAŻ WSZYSTKIE</v-btn>
                              </v-col>
                             
                          </v-row>
                      </v-card>
              </v-col>
          </v-row>
          <v-row v-if="showLogsTable">
              <v-col cols="12">
                  <v-card class="employee-list mb-1">
                      <v-card-title class="pa-6 pb-3">
                          <p>Lista aktywności</p>
                          <v-btn @click="closeModal()" style="margin-left:20px;">Zamknij</v-btn>
                          <v-spacer></v-spacer>
                          <v-text-field v-model="logs.search"
                                        append-icon="mdi-magnify"
                                        label="Szukaj"
                                        clearable
                                        single-line
                                        hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table v-model="logs.selected"
                                    :headers="logs.headers"
                                    :items="logs.lg"
                                    :search="logs.search"
                                    item-key="id"
                                    :hide-default-footer="false">
                          <template v-slot:[`item.show`]="{ item }">
                              <v-btn text @click="showSignal(item)">
                                  <v-icon color="green">mdi-open-in-new</v-icon><div>Pokaż</div>
                              </v-btn>
                          </template>
                      </v-data-table>
                  </v-card>
              </v-col>
          </v-row>
      </div>
  </v-container>
</template>

<script>
    import Service from '@/services/Service'
export default {
  name: 'Logs',
  data() {
    return {
        logs: [],
        lg: [],
        newMessages: [],
        logsDb: [],
        dates: [],
        selectedUser: 0,
        users: [],
        showLogsTable: false,
        signalId: 0
    }
  },
  computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
async mounted() {
    try {
        this.users = (await Service.getUsersForAdmin()).data        
        if (localStorage['sygnalIdForLogs'] == undefined || Object.keys(localStorage['sygnalIdForLogs']).length == 2 || JSON.parse(localStorage['sygnalIdForLogs']).sygnalid == 0) {
            this.logsDb = (await Service.getLogs(1)).data
        } else {
            this.signalId = JSON.parse(localStorage['sygnalIdForLogs']).sygnalid
            this.logsDb = (await Service.getLogsForSpecyficSignal(this.signalId)).data
            localStorage["sygnalIdForLogs"] = JSON.stringify({ sygnalid: 0 });
            this.signalId = 0
        }
        this.logs = {
            selected: [],
            search: '',
            headers: [
                {
                    text: 'Lp.',
                    align: 'start',
                    sortable: false,
                    value: 'lp',
                },
                { text: 'Data', value: 'datetime' },
                { text: 'Zgłoszenie', value: 'identifier' },
                { text: 'Użytkownik', value: 'user' },
                { text: 'Aktywność', value: 'text' },
                { text: '', value: 'show', sortable: false },
            ],
            lg: this.logsDb,
        },
        this.showLogsTable = true
    } catch (e) {
        console.log(e)
        if (e.response.status == 401)
            this.$router.push({ name: 'Login' })
    }
        
  },
  methods: {
      formatDateToDisplay(value) {
          var date = new Date(value)
          var d = date.toLocaleString('default', {
              year: 'numeric',
              month: 'long',
              weekday: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
          });

          return d;
      },
      selectThisUser(item) {
          this.selectedUser = item;
      },
      async findLogsAllForAdmin() {
          try {
            this.logsDb = (await Service.getLogs(0)).data;
              this.logs = {
                  selected: [],
                  search: '',
                  headers: [
                      {
                          text: 'Lp.',
                          align: 'start',
                          sortable: false,
                          value: 'lp',
                      },
                      { text: 'Data', value: 'datetime' },
                      { text: 'Zgłoszenie', value: 'identifier' },
                      { text: 'Użytkownik', value: 'user' },
                      { text: 'Aktywność', value: 'text' },
                      { text: '', value: 'show', sortable: false },
                  ],
                  lg: this.logsDb,
              };

              this.showLogsTable = true;

          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      closeModal() {
          this.logs = [];
          this.logsDb = [];
          this.lg = [];
          this.showLogsTable = false;
      },
      showSignal(item) {
          this.$router.push({ name: 'Signal', params: { identifier: item.msId } })
      },
      async findLogsForAdmin() {
          try {
              if (this.dates.length == 0 && this.selectedUser == 0) {
                  alert("Brak wskazania zakresu przeszukania");
                  return;
              }
              
            var temp = {};
            if (this.selectedUser > 0)
                temp.userId = this.selectedUser;
            else
                temp.userId = 0;

            if (this.dates.length > 0) {
                if (this.dates.length > 1) {
                    temp.date1 = this.dates[0];
                    temp.date2 = this.dates[1];
                } else {
                    temp.date1 = this.dates[0];
                }
            }

            var result = (await Service.getLogsForAdmin(temp)).data;
            if (Object.keys(result).length > 0) {
                this.logsDb = [];
                this.logs = {};

                this.logsDb = result;
                this.logs = {
                    selected: [],
                    search: '',
                    headers: [
                        {
                            text: 'Lp.',
                            align: 'start',
                            sortable: false,
                            value: 'lp',
                        },
                        { text: 'Data', value: 'datetime' },
                        { text: 'Zgłoszenie', value: 'identifier' },
                        { text: 'Użytkownik', value: 'user' },
                        { text: 'Aktywność', value: 'text' },
                        { text: '', value: 'show', sortable: false },
                    ],
                    lg: this.logsDb,
                };
                this.showLogsTable = true;
            } else {
                alert("Brak danych w wybranych kryteriach.");
            }

            this.dates = [];
            this.selectedUser = 0;

          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      }
  }
}

</script>

<style src="../Reports/Basic.scss" lang="scss">
</style>
