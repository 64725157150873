<template>
  <v-container fluid>
      <div class="tables-basic">
          <h1 class="page-title mt-10 mb-6">Użytkownicy</h1>
          <v-row v-if="showGlobalListModal">
              <v-col cols=12>
                  <v-card class="mb-1">
                      <v-card-title class="pa-5 pb-3">
                          <p>Link dla sygnalistów do wysyłki zgłoszeń: <a :href="domain" target="_blank"><b>{{domain}}</b></a></p>
                      </v-card-title>
                  </v-card>
              </v-col>
          </v-row>
          <v-row v-if="showGlobalListModal">
              <v-col cols=12>
                  <v-card class="mb-1">
                      <v-card-title class="pa-5 pb-3">
                          <p>Użytkownicy rozpatrujący zgłoszenia</p>
                          <v-spacer></v-spacer>
                          <v-btn v-if="adminlogedIn.type == 1" @click="addNewUser()">Dodaj</v-btn>
                      </v-card-title>
                      <v-simple-table>
                          <template v-slot:default>
                              <thead>
                                  <tr>
                                      <th class="text-left pa-6">IMIĘ I NAZWISKO</th>
                                      <th class="text-left">EMAIL</th>
                                      <th class="text-left">TELEFON</th>
                                      <th class="text-left">WIDZIANO</th>
                                      <th class="text-left">TYP</th>
                                      <th class="text-left">STATUS</th>
                                      <th class="text-left">ARCHIWUM</th>
                                      <th class="text-left">RAPORTY</th>
                                      <th class="text-left">KATEGORIE</th>
                                      <th v-if="adminlogedIn.type == 1" class="text-left"></th>
                                      <th class="text-left"></th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="item in users" :key="item.email">
                                      <td class="pa-6">{{ item.name }}</td>
                                      <td>{{ item.email }}</td>
                                      <td>{{ item.phone }}</td>
                                      <td>{{ item.lastLoginDate }}</td>
                                      <td>{{ formatTypeUser(item.type) }}</td>
                                      <td>{{item.status == 1 ? 'Aktywny' : 'Nieaktywny' }}</td>
                                      <td>{{item.canArchivize ? 'Tak' : 'Nie' }}</td>
                                      <td>{{item.canReports ? 'Tak' : 'Nie' }}</td>
                                      <td><div v-for="i in item.categoriesNames" :key="i">{{i}}</div></td>
                                      <td v-if="adminlogedIn.type == 1">
                                          <v-btn text @click="updateUser(item)">
                                              <v-icon color="green">mdi-circle-edit-outline</v-icon>
                                          </v-btn>
                                      </td>
                                      <td v-if="adminlogedIn.type == 1 && adminlogedIn.id !== item.id">
                                          <v-btn text @click="deleteUser(item.id)">
                                              <v-icon color="red">mdi-trash-can</v-icon>
                                          </v-btn>
                                      </td>
                                      <td v-else></td>
                                  </tr>
                              </tbody>
                          </template>
                      </v-simple-table>
                  </v-card>
              </v-col>
          </v-row>
          <v-row v-if="showAdminSettingsModal">
              <v-col cols="12">
                  <v-card class="pa-3 pb-3">
                      <v-card-title class="pa-6 pb-1">
                          <p v-if="name.length == 0">Dodawanie użytkownika</p>
                          <p v-else>Edycja użytkownika</p>
                          <v-spacer></v-spacer>
                          <v-btn v-if="adminlogedIn.type == 1" @click="closeModal()">Zamknij</v-btn>
                      </v-card-title>
                      <form class="pa-10 pb-10">
                          <v-text-field v-model="name"
                                        :error-messages="nameErrors"
                                        :counter="50"
                                        label="Imię i Nazwisko"
                                        required
                                        @input="$v.name.$touch()"
                                        @blur="$v.name.$touch()"></v-text-field>
                          <v-text-field v-model="email"
                                        :error-messages="emailErrors"
                                        label="E-mail"
                                        required
                                        @input="$v.email.$touch()"
                                        @blur="$v.email.$touch()"></v-text-field>
                          <v-text-field v-model="phoneNumber"
                                        @keypress="onlyNumber"
                                        :error-messages="errorsPhone"
                                        label="Numer telefonu"
                                        @input="$v.phoneNumber.$touch()"
                                        @blur="$v.phoneNumber.$touch()"></v-text-field>
                          <v-checkbox v-model="archivize"
                                      label="Czy użytkownik może archiwizować zgłoszenia?"
                                      @change="$v.archivize.$touch()"
                                      @blur="$v.archivize.$touch()"></v-checkbox>
                          <v-checkbox v-model="reports"
                                      label="Czy użytkownik może pobierać raporty?"
                                      @change="$v.reports.$touch()"
                                      @blur="$v.reports.$touch()"></v-checkbox>
                          <v-checkbox v-model="activeAccount"
                                      label="Czy użytkownik ma konto aktywne?"
                                      @change="$v.activeAccount.$touch()"
                                      @blur="$v.activeAccount.$touch()" :disabled="tempAdminType == 1"></v-checkbox>
                          <br /><br />
                          <v-btn class="mr-4"
                                 @click="submit" color="green" :disabled="name.length == 0 || name.length > 50 || email.length == 0 || phoneNumber.length > 9 || emailErrors.length > 0">
                              Zatwierdź
                          </v-btn>
                          <v-btn @click="clear">
                              Wyczyść pola
                          </v-btn>
                      </form>
                  </v-card>

              </v-col>
          </v-row>
      </div>
    
  </v-container>
  
</template>

<script>
    import Service from '@/services/Service'
    import { validationMixin } from 'vuelidate'
    import { required, maxLength, email } from 'vuelidate/lib/validators'
export default {
  name: 'Users',
mixins: [validationMixin],
validations: {
    name: { required, maxLength: maxLength(50) },
    email: { required, email },
    phoneNumber: { maxLength: maxLength(9) },
    archivize: {
        checked(val) {
            return val
        },
    },
    reports: {
        checked(val) {
            return val
        },
    },
    activeAccount: {
        checked(val) {
            return val
        },
    },
},
  data() {
    return {
      users: [],
      showAdminSettingsModal: false,
        main: [],
        adminlogedIn: {},
        domain: '',
        name: '',
        email: '',
        archivize: false,
        reports: false,
        activeAccount: true,
        phoneNumber: '',
        tempAdminIdToEdit: 0,
        tempAdminType: 0,
        showGlobalListModal: true
    }
},
computed: {
    nameErrors() {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.maxLength && errors.push('Pole nie może mieć więcej niż 50 znaków')
        !this.$v.name.required && errors.push('Pole jest wymagana.')
        return errors
    },
    errorsPhone() {
        const errors = []
        if (!this.$v.phoneNumber.$dirty) return errors
        !this.$v.phoneNumber.maxLength && errors.push('Pole nie może mieć więcej niż 9 znaków')
        return errors
    },
    emailErrors() {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Zły format email')
        !this.$v.email.required && errors.push('E-mail jest wymagany')
        return errors
    },
},
async mounted() {
    try {
        this.main = (await Service.getUsersForUsersPanel()).data,
        this.users = this.main.users,
        this.domain = this.main.domainName,
        this.adminlogedIn = this.main.admin

    } catch (e) {
        
        if (e.response.status == 401) {
            this.$router.push({ name: 'Login' })
        }
    }
  },
methods: {
    onlyNumber($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57)) { 
            $event.preventDefault();
        }
    },
    async submit() {
        var isNew = true;
        if (this.tempAdminIdToEdit > 0)
            isNew = false;

        var temp = {
            name: this.name,
            email: this.email,
            phone: this.phoneNumber,
            canArchivize: this.archivize,
            canReports: this.reports,
            isNew: isNew,
            id: this.tempAdminIdToEdit
        };

        if (this.activeAccount)
            temp.status = 1;
        else
            temp.status = 0;

        try {
            var result = (await Service.addOrUpdateUser(temp)).data;
            if (Object.keys(result).length > 0) {

                if (this.users == null)
                    this.users = [];

                var index = this.users.findIndex(x => x.id == result.id);
                if (index != -1) {
                    this.users[index].id = result.id;
                    this.users[index].name = result.name;
                    this.users[index].email = result.email;
                    this.users[index].phone = result.phone;
                    this.users[index].lastLoginDate = result.lastLoginDate;
                    this.users[index].status = result.status;
                    this.users[index].canArchivize = result.canArchivize;
                    this.users[index].canReports = result.canReports;
                }
                else {
                    this.users.push(result);
                }

                this.name = '';
                this.email = '';
                this.archivize = false;
                this.reports = false;
                this.activeAccount = false;
                this.phoneNumber = '';
                this.tempAdminIdToEdit = 0;
                this.showAdminSettingsModal = false;
                this.tempAdminType = 0;
                this.showGlobalListModal = true;
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString());

            if (e.response.status == 401)
                this.$router.push({ name: 'Login' })
        }
    },
    clear() {
        this.name = '';
        this.email = '';
        this.archivize = false;
        this.reports = false;
        this.activeAccount = true;
        this.phoneNumber = '';
        this.tempAdminIdToEdit = 0;
        this.tempAdminType = 0;
    },
    closeModal(){
        this.showAdminSettingsModal = false;
        this.name = '';
        this.email = '';
        this.archivize = false;
        this.reports = false;
        this.activeAccount = false;
        this.phoneNumber = '';
        this.tempAdminIdToEdit = 0;
        this.tempAdminType = 0;
        this.showGlobalListModal = true;
    },
      formatTypeUser(type) {
          if (type == 1)
              return 'Administrator'
          if (type == 2)
              return 'Użytkownik'
          if (type == 3)
              return 'Audytor'
      },
    addNewUser() {
        this.showGlobalListModal = false;
        this.showAdminSettingsModal = true;
    },
    async deleteUser(value) {
        try {
            if (confirm('Czy usunąć trwale użytkownika z systemu?')) {

                var result = (await Service.removeUser(value));
                if (result.status == 200) {
                    var index = this.users.findIndex(x => x.id == value);
                    if (index != -1) {
                        this.users.splice(index, 1);
                    }
                }
            }
            else {
                return;
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString())
            if (e.response.status == 401)
                this.$router.push({ name: 'Login' })
        }
    },
    updateUser(value) {
        this.name = value.name;
        this.email = value.email;
        this.archivize = value.canArchivize;
        this.reports = value.canReports;

        if (value.status == 1)
            this.activeAccount = true;
        else
            this.activeAccount = false;

        this.phoneNumber = value.phone;
        this.tempAdminIdToEdit = value.id;
        this.tempAdminType = value.type;
        this.showGlobalListModal = false;
        this.showAdminSettingsModal = true;
    }
  }
}

</script>

<style src="./Basic.scss" lang="scss"></style>
