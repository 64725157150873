<template>
  <v-app>
    <v-container fluid id="login">
      <v-row no-gutters>
        <v-col cols="3" class="main-part d-none d-md-none d-lg-flex">
            <div class="d-flex">
                <v-img src="@/assets/sygnali_logo_450.png" contain style="width:60%"></v-img>
                <span style="color:white">PLATFORMA OCHRONY SYGNALISTÓW</span>
            </div>
        </v-col>
        <v-col cols="12" lg="9" class="login-part d-flex align-center justify-center">
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper pt-md-4 pt-0">
                <v-tabs fixed-tabs>
                    
                  <v-tab :href="`#tab-login`">
                    ZALOGUJ
                  </v-tab>
                   <v-tab :href="`#tab-newUser`">
                    REJESTRACJA
                  </v-tab> 

                  <v-tab-item :value="'tab-login'" >
                    <v-form>
                      <v-container>
                        <v-row class="flex-column">
                          <v-col cols="12" class="d-flex align-center my-8">
                          </v-col>
                          <v-form>
                            <v-col>
                              <v-text-field
                                  v-model="email"
                                  :rules="emailRules"
                                  value=""
                                  label="Adres Email"
                                  required
                                  autocomplete ="off"
                              ></v-text-field>
                              <v-text-field
                                  v-model="password"
                                  :rules="passRules"
                                  type="password"
                                  autocomplete ="off"
                                  label="Hasło"
                                  hint="Minimum 6 zanków"
                                  required
                              ></v-text-field>

                            </v-col>
                            <v-col class="d-flex justify-space-between">
                                <v-btn class="text-capitalize"
                                       large
                                       :disabled="password.length === 0 || email.length === 0"
                                       color="primary"
                                       @click="login()">
                                    Zaloguj
                                </v-btn>
                                <v-btn large text class="text-capitalize primary--text" @click="passwordReset()">Resetuj hasło</v-btn>
                            </v-col>
                          </v-form>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-tab-item>

                  <v-tab-item :value="'tab-newUser'">
                      <v-form>
                          <v-container>
                              <v-row class="flex-column">

                                  <v-col>
                                      <p class="login-slogan display-2 text-center font-weight-medium mt-10">Witaj!</p>
                                      <p class="login-slogan display-1 text-center font-weight-medium mb-10">Zarejestruj się</p>
                                  </v-col>

                                  <v-form>
                                      <v-col>
                                          <v-text-field v-model="createEmail"
                                                        :rules="emailRules"
                                                        label="Adres Email"
                                                        required></v-text-field>
                                      </v-col>
                                      <div style="font-size:0.8em" class="col">Akceptuję <a href=https://sygnali.pl/regulamin target="_blank">Regulamin serwisu</a> oraz <a href=https://sygnali.pl/polityka_prywatnosci target="_blank">Politykę Prywatności</a>.</div>
                                      <v-col class="d-flex justify-space-between">
                                          <v-btn large
                                                 block
                                                 :disabled="createEmail.length === 0"
                                                 color="primary"
                                                 @click="created()">
                                              Rejestruj
                                          </v-btn>
                                      </v-col>
                                  </v-form>
                              </v-row>
                          </v-container>
                      </v-form>
                  </v-tab-item>

                </v-tabs>
              </div>
            </v-col>
            <!-- <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text">© 2014-2020 <a href="https://flatlogic.com/" class="text-decoration-none">Flatlogic</a>, LLC. All rights reserved.</div>
              </v-footer>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
    import Service from '@/services/Service'
    import $ from 'jquery'
  export default {
    name: 'Login',
    data() {
      return {
        email: '',
        emailRules: [
          v => !!v || 'E-mail jest wymagany',
          v => /.+@.+/.test(v) || 'Niepoprawny E-mail',
        ],
        createFullName: '',
        createEmail: '',
        createPassword: '',
        password: '',
        passRules: [
          v => !!v || 'Hasło jest wymagane',
          v => v.length >= 6 || 'Minimum 6 zanków'
        ]
      }
    },
    mounted() {
        if (window.location.href.includes("zgloszenie.sygnali.pl")) {
            this.$router.push({ name: 'InboxPhoneLogin' })
        }
    },
    created(){
        document.title = "Sygnali.pl";
    },
    methods: {
    validateEmail(email) {
        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    passwordReset() {
        this.$router.push('/resetpassword');
    },
    async login() {
        try {
            const user = {
                email: this.email,
                password: this.password
            }

            const result = (await Service.loginUser(user));
            if (result.status == 200) {
                var thisVue = this;
                $.getJSON("https://app.wza24.pl/ajax/api/getip", function (json) {
                    thisVue.$store.commit('changeAdminIp', json.ip);
                });
                
                this.$router.push('/dashboard');
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString());
            return;
        }
    },
    async created() {
        try {
            if (!this.validateEmail(this.createEmail)) {
                alert("Wprowadź poprawny email")
                return;
            }

        const user = {
            email: this.createEmail,
        }

        const result = (await Service.register(user));

            if (result.status == 200) {
                alert("Na wskazany adres email wysłano link aktywacyjny")
                return;
        }
    } catch (e) {
        this.$router.push({ name: 'Error' });
    }
}
},
  }

</script>

<style src="./Login.scss" lang="scss"/>
